import { Link, StaticQuery } from "gatsby"
import React from "react"

import "../scss/modules/header-component.scss"
import logo from "../images/logo.png"
import "bootstrap/dist/css/bootstrap.css"
import { graphql } from "gatsby"

class header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
    }
  }

  toggle = () => {
    this.setState({
      active: !this.state.active,
    })
  }

  render() {
    const { active } = this.state
    const Links = () => {
      return (
        <StaticQuery
          query={graphql`
            query {
              allStrapiUrl {
                nodes {
                  ResultatExamen
                  PrendreRendeVous
                }
              }
            }
          `}
          render={data => (
            <div className={"main-links"}>
              <a
                className={"item-main col-md-6"}
                href={data.allStrapiUrl.nodes[0].PrendreRendeVous}
                target="_blank"
                rel="noreferrer"
              >
                Prendre rendez-vous
              </a>
              <a
                className={"item-main blue col-md-6"}
                href={data.allStrapiUrl.nodes[0].ResultatExamen}
                target="_blank"
                rel="noreferrer"
              >
                Vos résultats d'examens
              </a>
            </div>
          )}
        />
      )
    }
    return (
      <div
        className={
          active
            ? "header-component col-md-9 active"
            : "header-component col-md-9"
        }
      >
        <Link to="/">
          <img src={logo} alt="logo-brand" className={"logo"} />
        </Link>
        <div
          role="button"
          className={"burger blue"}
          onClick={() => this.toggle()}
          tabIndex={0}
        >
          <span className={"line1"}></span>
          <span className={"line2"}></span>
          <span className={"line3"}></span>
        </div>
        <div className={"content-header col-md-12"}>
          <div className={"main-links col-md-4"}>
            <Links />
          </div>
          <ul className={"nav col-md-8"}>
            <li className={"col-lg-2"}>
              <Link className={"item-nav"} activeClassName="current" to="/">
                Accueil
              </Link>
            </li>
            <li className={"col-lg-2"}>
              <Link
                className={"item-nav"}
                activeClassName="current"
                to="/Examens"
              >
                Les examens
              </Link>
            </li>
            <li className={"col-lg-2"}>
              <Link
                className={"item-nav"}
                activeClassName="current"
                to="/equipe"
              >
                L'équipe
              </Link>
            </li>
            <li className={"col-lg-2"}>
              <Link
                className={"item-nav"}
                activeClassName="current"
                to="/engagement"
              >
                Nos engagements
              </Link>
            </li>
            <li className={"col-lg-2"}>
              <Link className={"item-nav"} activeClassName="current" to="/info">
                Infos Pratiques
              </Link>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default header
