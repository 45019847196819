/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import "../scss/modules/layout.scss"
import { graphql, StaticQuery } from "gatsby"

const Links = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allStrapiUrl {
            nodes {
              ResultatExamen
              PrendreRendeVous
            }
          }
        }
      `}
      render={data => (
        <div className={"main-links-bottom-responsive"}>
          <a
            className={"item-main col-md-6"}
            href={data.allStrapiUrl.nodes[0].ResultatExamen}
            target="_blank"
            rel="noreferrer"
          >
            Prendre rendez-vous
          </a>
          <a
            className={"item-main blue col-md-6"}
            href={data.allStrapiUrl.nodes[0].PrendreRendeVous}
            target="_blank"
            rel="noreferrer"
          >
            Résultats examens
          </a>
        </div>
      )}
    />
  )
}
const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <div className={"layout"}>
        <main className={"main"}>
          {children}

          <Links />
        </main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
